{
  "accessDenied": {
    "description": "You don't have required permissions to view this page.",
    "title": "Access denied"
  },
  "action": {
    "activate": "Activate",
    "activateOrDeactivate": "Activate/Deactivate",
    "add": "Add",
    "addToCollection": "Add to recipe book",
    "apply": "Apply",
    "applyFilters": "Apply filters",
    "approve": "Approve",
    "approveTranslations": "Approve translations",
    "assignUnassignToMyCountryProfile": "Assign/Unassign to my Country Profile",
    "blockConsumer": "Block consumer",
    "change": "Change",
    "changeIngredients": "Change ingredients",
    "clear": "Clear",
    "deactivate": "Deactivate",
    "deflag": "Deflag",
    "delete": "Delete",
    "deleteSet": "Delete set",
    "edit": "Edit",
    "export": "Export",
    "exportTranslations": "Export translations",
    "flagComment": "Report",
    "hideFilters": "Hide filters",
    "hideLog": "Hide log",
    "hidePreparedMeals": "Hide images",
    "lock": "Lock",
    "moveTo": "Move to",
    "moveToDraft": "Move to draft",
    "moveToGlobal": "Move to GLOBAL",
    "moveToLive": "Move to live",
    "pin": "Pin",
    "publish": "Publish",
    "publishContent": "Publish content",
    "publishUnpublish": "Publish/Unpublish",
    "quarantine": "Quarantine",
    "reactivate": "Reactivate",
    "reassign": "Reassign",
    "reject": "Reject",
    "rejectRequest": "Reject request",
    "remove": "Remove",
    "replace": "Replace",
    "report": "Report",
    "requestEdit": "Request edit",
    "requestEditAndTakeOffline": "Request edit and take offline",
    "requestReview": "Request review",
    "revokeReporting": "Revoke report",
    "saveAndApprove": "Save and approve",
    "saveAnyway": "Save anyway",
    "saveSet": "Save set",
    "schedule": "Schedule",
    "selectFile": "Select file",
    "showFilters": "Show filters",
    "showLog": "Show log",
    "showPreparedMeals": "Show images",
    "takeOffline": "Take offline",
    "translate": "Translate",
    "unapprove": "Unapprove",
    "unlock": "Unlock",
    "unpin": "Unpin"
  },
  "allergen": {
    "celery": "Celery",
    "cerealsContainingGluten": "Cereals containing gluten",
    "crustaceans": "Crustaceans",
    "eggs": "Eggs",
    "fish": "Fish",
    "lupin": "Lupin",
    "milk": "Milk",
    "molluscs": "Molluscs",
    "mustard": "Mustard",
    "noAllergens": "There are no allergens for this ingredient",
    "nuts": "Nuts",
    "peanuts": "Peanuts",
    "placeholder": "Select allergens",
    "sectionTitle": "Food sensitivities and intolerances",
    "sesameSeeds": "Sesame Seeds",
    "soybeans": "Soybeans",
    "sulphurDioxideAndSulphites": "Sulphur dioxide and suplhites",
    "title": "Allergens"
  },
  "announcement": {
    "browse": {
      "title": "Browse announcements"
    },
    "buttonText": "Button text",
    "collectionID": "Recipe book ID",
    "collectionTitle": "Recipe book title",
    "content": "Content",
    "create": "Create announcement",
    "details": "Announcement details",
    "edit": "Edit announcement",
    "enterButtonText": "Enter button text",
    "enterDescription": "Enter announcement description",
    "enterLink": "Enter link",
    "enterTitle": "Enter announcement title",
    "errors": {
      "domain": {
        "required": "Domain is required"
      },
      "generic": {
        "description": "Error is displayed in the console. Please contact developers.",
        "title": "Error while saving the announcement"
      },
      "imageRequired": "Image is required",
      "language": {
        "required": "Language is required"
      }
    },
    "hoverForPreview": "Hover for 9:16 preview",
    "recipeID": "Recipe ID",
    "recipeTitle": "Recipe title",
    "save": "Save",
    "saveTheAnnouncement": "Save the announcement?",
    "savedToastMessage": "Announcement saved",
    "tipID": "Article ID",
    "tipTitle": "Article title",
    "title": "Announcement title",
    "type": "Announcement type",
    "uploadImage": "Upload image",
    "view": "View announcement",
    "wantToSaveBeforeLeaving": "Do you want to save the changes before leaving?"
  },
  "announcementType": {
    "collection": "Recipe book",
    "none": "General",
    "outgoing": "Link",
    "recipe": "Recipe",
    "store": "Store",
    "tip": "Article"
  },
  "authorType": {
    "consumer": {
      "label": "User generated",
      "shortLabel": "C"
    },
    "philips": {
      "label": "Philips managed",
      "shortLabel": "P"
    }
  },
  "breakdownTable": {
    "breakdown": "Breakdown",
    "per100g": "per 100 g",
    "per100ml": "Per 100 ml",
    "percentDailyValue": "% NRV*"
  },
  "bulkActions": {
    "contentList": {
      "choose": "Choose a content list",
      "description": "Select a content list and choose an action below in the footer bar menu.",
      "discardEdit": {
        "content": "Are you sure you want to leave the edit form and discard the changes you made? "
      },
      "header": {
        "actions": "Actions",
        "content": "Content"
      },
      "label": "Content list",
      "saved": "Content list is saved"
    },
    "contentPreview": "Content preview ({{ contentCount }})",
    "filterSetup": {
      "description": "Get a preview of selected filters and choose an action below in the footer bar menu."
    },
    "history": {
      "title": "Action history log"
    },
    "selectionType": {
      "contentLists": "Content lists"
    }
  },
  "button": {
    "add": "Add",
    "cancel": "Cancel",
    "close": "Close",
    "collapse": "Collapse",
    "deflag": "Deflag",
    "delete": "Delete",
    "discard": "Discard",
    "edit": "Edit",
    "hide": "Hide {{ label }}",
    "no": "No",
    "ok": "OK",
    "post": "Post",
    "preview": "Preview",
    "resolve": "Resolve",
    "save": "Save",
    "saveChanges": "Save changes",
    "search": {
      "clear": "Clear search",
      "search": "Search",
      "searchItems": "Search {{ items }}"
    },
    "show": "Show {{ label }}",
    "stay": "Stay",
    "unresolve": "Unresolve",
    "yes": "Yes",
    "yesChange": "Yes, change",
    "yesDelete": "Yes, delete"
  },
  "changelog": {
    "header": {
      "subtitle": "Lists of changes for each release of HomeID Administrative portal",
      "title": "Release notes"
    },
    "notes": {
      "hide": "Hide notes",
      "show": "Show notes"
    },
    "subtitle": {
      "defects": "Bug fixes",
      "maintenanceStories": "Maintenance",
      "notes": "Notes",
      "userStories": "Features"
    }
  },
  "claimLimitType": {
    "max": "Upper",
    "min": "Lower"
  },
  "collection": {
    "addBasicInformation": "Add basic information",
    "addRecipe": "Add",
    "addRecipesToCollection": "Add recipes to the recipe book",
    "addedRecipesToCollection": "Added recipes to the recipe book  ({{ currentValue }})",
    "changeStatus": {
      "approval": {
        "content": "You are about to approve this recipe book. Please enter your approval reason:",
        "title": "Recipe book approval"
      },
      "deflag": {
        "content": "You are about to deflag this recipe book and move it back to Live. Please enter your reason:",
        "title": "Deflagging recipe book"
      },
      "deletion": {
        "content": "Are you sure you want to delete a recipe book?",
        "title": "Recipe book deletion"
      },
      "generic": {
        "content": "You are about to change the recipe book status. Please enter your reason:",
        "title": "Changing recipe book status"
      },
      "inReview": {
        "content": "You are about to move recipe book in review. Please enter your reason:",
        "title": "Move to in review"
      },
      "moveToDraft": {
        "content": "You are about to move this recipe book to draft. Please enter your reason:",
        "title": "Recipe book draft"
      },
      "moveToLive": {
        "content": "You are about to move this recipe book to live. Please enter your reason:",
        "title": "Moving the recipe book to Live"
      },
      "rejectRequest": {
        "content": "You are about to reject this request. Please enter your rejection reason:",
        "title": "Reject approving request"
      },
      "takeOffline": {
        "content": "You are about to unpublish the recipe book from all of the country profiles. Please enter your reason:",
        "title": "Taking the recipe book offline"
      }
    },
    "create": "Create {{device}} recipe book",
    "createUniversal": "Create recipe book",
    "description": "Description",
    "details": "Recipe book details",
    "edit": "Edit {{device}} recipe book",
    "editRecipeList": {
      "label": "Edit recipe list"
    },
    "enterDescription": "Enter recipe book description",
    "enterTitle": "Enter recipe book title",
    "hoverForPreview": "Hover for 3:4 preview",
    "id": "Recipe book ID",
    "incompatibleRecipes": {
      "proceedLabel": "Remove them from the recipe book",
      "removed": "Recipes successfully removed from the recipe book.",
      "title": "The following recipes in the recipe book are not compatible with the recipe book."
    },
    "missingDialogOptions": {
      "question": "Do you want to edit the recipe book now?"
    },
    "noRecipesAddedToCollection": "There are no recipes added to this recipe book.",
    "noRecipesFound": "No recipes found",
    "notFound": "Recipe book not found",
    "placeholder": {
      "noRecipes": "Recipe book does not have any recipes"
    },
    "reassignLanguage": {
      "description": "Choose a supported language of the recipe book's country.",
      "toastMessage": "Recipe book language was changed to {{ language }}"
    },
    "recipeNotValid": {
      "defaultError": {
        "description": "The recipe and the recipe book do not match."
      },
      "noMatchingDomains": {
        "description": "Recipe {{recipeTitle}} and the recipe book do not have any matching domains. The recipe must be global or have the same domain as the recipe book."
      },
      "translationNotApproved": {
        "description": "Recipe {{recipeTitle}} and the recipe book do not have any approved translations in common.",
        "title": "Recipe is not valid for the recipe book"
      },
      "wrongContentStatus": {
        "description": "Recipe {{recipeTitle}} and the recipe book do not have matching appliances."
      }
    },
    "recipeRemovalDescription": "Are you sure you want to remove the recipe from the recipe book?",
    "recipeRemovalTitle": "Remove from recipe book",
    "save": "Save",
    "saveTheCollection": "Save the recipe book?",
    "searchAddedRecipes": "Search added recipes",
    "searchRecipes": "Search recipes",
    "status": {
      "toast": "Recipe book status has been successfully changed to \"{{ status}}\""
    },
    "title": "Recipe book title",
    "toastMessage": {
      "saved": "Recipe book saved",
      "trasnlationSaved": "Recipe book translation saved successfully."
    },
    "translationErrors": {
      "descriptionRequired": "Recipe book description is required.",
      "titleRequired": "Recipe book title is required."
    },
    "validators": {
      "recipesRequired": "Recipe book must have at least one recipe."
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe book information you entered. Do you want to save the recipe book to continue editing later?"
  },
  "collectionBundle": {
    "contentCategory": {
      "choose": "Choose appliance",
      "singular": "Appliance"
    },
    "searchCollections": "Search recipe books ",
    "selectApplianceFirst": "Select appliance first"
  },
  "collectionPack": {
    "activation": {
      "contentWillBeDeactivated": "<div>You are activating recipe book pack for {{ country }}, therefore, {{ collectionBundlesForDeactivation }} will be deactivated.</div>"
    },
    "addBasicInformation": "Add basic information",
    "addCollectionsToPack": "Add recipe books to the pack (min.{{ minCollections }})",
    "addedCollectionsToPack": "Added recipe books to the pack ({{ currentValue }})",
    "collectionRemovalDescription": "Are you sure you want to remove the recipe book from the pack?",
    "collectionRemovalTitle": "Remove from pack",
    "collections": {
      "emptyStateMessage": "There are no recipe books in this pack."
    },
    "details": "Recipe book pack details",
    "enterTitle": "Enter title",
    "noCollectionsAddedToCollectionPack": "There are no recipe books added to this pack.",
    "noCollectionsFound": "No recipe books found for ",
    "saveTheCollectionPack": "Do you want to save the changes before leaving?",
    "searchAddedCollections": "Search added recipe books",
    "title": "Recipe book pack title",
    "toastMessage": {
      "saved": "Recipe book pack saved"
    },
    "validation": {
      "notEnoughCollectionsForCountry": "At least {{ numberOfCollections }} recipe books from the recipe book pack must be published to the country."
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe book pack information you entered. Do you want to save the recipe book pack to continue editing later?"
  },
  "collections": {
    "notFound": "No recipe books found"
  },
  "comment": {
    "browse": {
      "all": "Browse comments"
    },
    "commentFrom": "Comment from {{ user }}",
    "deflagging": "Deflagging comment",
    "deflaggingReasons": "You are about to deflag this comment. Please enter your reason:",
    "deleteModal": {
      "description": "Are you sure you want to delete this comment?",
      "title": "Deleting comment"
    },
    "details": "Comment details",
    "editComment": "Edit comment",
    "flag": {
      "content": "You are about to report this consumer’s comment. Please select your reporting reason:",
      "subtitle": "Flagging reason",
      "title": "Report {{ user }}'s comment"
    },
    "inRecipe": "In recipe",
    "inTip": "In article",
    "plural": "comments",
    "postComment": "Post comment",
    "postNewComment": "Post new comment",
    "resolved": "Resolved",
    "singular": "comment",
    "viewRecipe": "View recipe",
    "viewTip": "View article"
  },
  "comments": {
    "commentsByConsumers": "Comments by consumers",
    "noComments": "No comments",
    "statusIndicatorLabel": "Unresolved comments"
  },
  "common": {
    "add": "Add",
    "addTags": "Add tags",
    "amount": "Amount",
    "announcement": {
      "plural": "Announcements"
    },
    "any": "Any",
    "appliance": {
      "choose": "Choose appliance(s)",
      "plural": "Appliances",
      "singular": "Appliance"
    },
    "author": {
      "singular": "Author"
    },
    "availableInformation": "Available information",
    "browse": {
      "collections": "Browse recipe books",
      "consumerDeletionRequests": "Consumer deletion requests",
      "consumerList": "Consumer list",
      "customTables": "Browse custom tables",
      "homescreenCarousels": "Home Screen carousels",
      "ingredients": "Browse ingredients",
      "languageSelect": {
        "ingredients": "Display ingredients in language"
      },
      "noResultsPlacehholder": {
        "subtitle": "There are no results for this search. Please try another search.",
        "subtitleDefault": "There are currently no results.",
        "title": "No results found for '#s'",
        "titleDefault": "No results"
      },
      "noResultsPlacehholderInject": {
        "subtitleDefault": "There are currently no {{ contentType }}.",
        "subtitleDefaultSingular": "There is currently no {{ contentType }}.",
        "titleEmpty": "No {{ contentType }}"
      },
      "noResultsPlaceholderInject": {
        "title": "No {{ contentType }} found for {{ query }}"
      },
      "nutriUStories": "Browse articles",
      "packs": "Browse packs",
      "plans": "Browse message plans",
      "recipes": "Browse recipes",
      "reportedContent": "Reported content"
    },
    "bulkActions": {
      "subtitle": "Content translations and management.",
      "title": "Translations and content actions"
    },
    "cancel": "Cancel",
    "choose": {
      "domain": "Choose domain",
      "language": "Choose language"
    },
    "collection": {
      "plural": "recipe books"
    },
    "collectionPack": {
      "plural": "recipe book packs",
      "singular": "Recipe book pack"
    },
    "collectionType": {
      "manual": "Manual"
    },
    "comment": {
      "plural": "Comments",
      "singular": "Comment"
    },
    "conjunction": {
      "and": " and ",
      "or": " or "
    },
    "consumer": {
      "plural": "Consumers",
      "singular": "Consumer"
    },
    "consumersReported": "Consumers reported",
    "contentManagement": "Content management",
    "continueEditing": "Continue editing",
    "conversion": "Conversion",
    "country": {
      "singular": "Country"
    },
    "createdBy": "Created by",
    "dateAndTime": "Date & Time",
    "degreeCelsius": "°C",
    "delete": "Delete",
    "deselectFromAllPages": "Deselect from all pages",
    "dialog": {
      "areYouSure": "Are you sure?"
    },
    "domain": {
      "plural": "Domains",
      "singular": "domain"
    },
    "edit": "Edit",
    "error": {
      "invalidInput": "Invalid input"
    },
    "event": {
      "singular": "event"
    },
    "filterSetup": "Filter setups",
    "firstTimeReported": "First time reported",
    "hide": "Hide",
    "history": "history",
    "ingredient": {
      "plural": "ingredients"
    },
    "insert": "Insert",
    "lastTimeReported": "Last time reported",
    "locked": "Locked",
    "noResult": {
      "plural": "There are no {{ item }}",
      "singular": "There is no {{ item }}"
    },
    "none": "None",
    "notPublished": "Not published",
    "note": "Note",
    "numberOf": {
      "comments": "comments",
      "favorites": "favorites",
      "recipes": "recipes",
      "views": "views"
    },
    "nutriUStory": {
      "plural": "Articles"
    },
    "nutritionalClaim": {
      "plural": "Nutritional claims"
    },
    "philipsUser": "Philips user",
    "pinnedCollection": {
      "plural": "pinned recipe books",
      "singular": "Pinned recipe book"
    },
    "priority": "Priority",
    "product": {
      "plural": "Product models",
      "singular": "Product model"
    },
    "productRange": "Product range",
    "pushMessage": {
      "singular": "Push notification"
    },
    "recipe": {
      "byConsumer": "By Consumer",
      "byPhilips": "By Philips",
      "plural": "recipes"
    },
    "recipeBook": {
      "singular": "Recipe book"
    },
    "recipeBookCarousel": {
      "plural": "Recipe book carousels",
      "singular": "Recipe book carousel"
    },
    "recipeCarousel": {
      "plural": "Recipe carousels",
      "singular": "Recipe carousel"
    },
    "recipeList": {
      "singular": "Recipe list"
    },
    "referenceQuantityAndUnit": "Per {{quantity}} {{unit}}",
    "remove": "Remove",
    "report": {
      "plural": "reports"
    },
    "reportedContent": {
      "plural": "reported content"
    },
    "reporterCountriesNo": "No. of reporter countries",
    "reporterLanguagesNo": "No. of reporter languages",
    "save": "Save",
    "selectAllOnThisPage": "Select all on this page",
    "selectCountry": "Select country",
    "selectCountryProfile": "Select country profile",
    "selectTimeRange": "Select time range",
    "serving": {
      "plural": "servings"
    },
    "show": "Show",
    "sourceAppliance": {
      "singular": "Source product"
    },
    "status": "Status",
    "steps": "Steps",
    "timeAndServings": "Time and servings",
    "toast": {
      "countryProfilesChanged": "Country profiles changed successfully"
    },
    "translation": {
      "plural": "translations"
    },
    "translationHasBeenDeleted": "The translation has been deleted",
    "unlocked": "Unlocked",
    "video": "Video",
    "view": {
      "addAnnouncementContent": "Add announcement content",
      "addBasicInformation": "Add basic information",
      "author": "Author",
      "basicInformation": "Basic information",
      "countriesActivated": "Countries activated",
      "description": "Description",
      "domainOwner": "Domain owner",
      "domainsAndCountriesPublished": "Domains and countries published",
      "lastModified": "Last modified",
      "sourceLanguage": "Source language",
      "statusChangedAt": "Changed at",
      "statusChangedBy": "Changed by",
      "tags": "Tags"
    },
    "viewItem": "View item"
  },
  "constraints": {
    "amount": {
      "greaterThan": "Amount must be greater than {{ amount }}"
    },
    "imageUpload": {
      "generic": {
        "description": "Image might be too large",
        "title": "Error while uploading image"
      },
      "mimeType": {
        "description": "Please use images only in PNG or JPG format.",
        "title": "Image is not in supported format"
      },
      "ratio": {
        "description": "Please use images only in aspect ratio {{ ratioX }}:{{ ratioY }}.",
        "title": "Image is not in the right aspect ratio"
      },
      "resolution": {
        "description": "Please upload an image larger than {{ minWidth }}×{{ minHeight }} px.",
        "title": "Image is not large enough"
      },
      "size": {
        "description": "Maximum image size is 20MB",
        "title": "Image is too large"
      }
    }
  },
  "consumer": {
    "email": "E-mail",
    "followers": "Followers",
    "following": "Following",
    "name": "Name",
    "noCountry": "-",
    "profileDescription": "Profile description",
    "registeredAt": "Registered at"
  },
  "consumers": {
    "rightToBeForgotten": {
      "modalDescription": "The entire backend data, which is referring to the user, shall be deleted",
      "modalTitle": "Are you sure you want to delete this consumer?",
      "searchPlaceholder": "Find consumer by e-mail address"
    }
  },
  "content": {
    "reassignLanguage": {
      "title": "Reassign language"
    },
    "sourceDeviceCannotBeDeselected": "Source product cannot be removed from product range",
    "translation": {
      "isApproved": "Translation already approved"
    }
  },
  "contentDraftStatusReason": {
    "approvedButMovedForEditing": "Approved but moved to draft for editing",
    "justCreated": "Just created",
    "reportedAndEditRequested": "Reported and edit requested",
    "reviewRequestedButRejected": "Review requested but rejected",
    "takenOffline": "Taken offline"
  },
  "contentScheduler": {
    "confirmationDialog": {
      "description": {
        "remove": "Are you sure you want to remove this event?",
        "schedule": "Are you sure you want to schedule this event?",
        "unschedule": "Are you sure you want to unschedule this event?"
      }
    },
    "eventStatus": {
      "draft": "draft",
      "failed": "failed",
      "passed": "past event",
      "scheduled": "scheduled"
    },
    "successToast": {
      "remove": "The event was removed successfully",
      "schedule": "You successfully scheduled content for {{ date }}",
      "unschedule": "The event was unscheduled successfully"
    }
  },
  "contentStatus": {
    "moveToLive": {
      "warning": "The {{ content }} has translations that are still in the draft status"
    }
  },
  "contentTranslations": {
    "addFilesForImport": "Add files for import",
    "clickSelectFileOrDrag": "Click select file or drag and drop your {{ fileTypes }} files",
    "import": "Import",
    "importTitle": "Import translations from files",
    "translationFiles": "Translation files"
  },
  "contentType": {
    "announcement": "Announcement",
    "collection": "recipe book",
    "healthClaimGuideline": "Health claim guideline",
    "nutritionClaimContent": "Nutrition claim content",
    "recipe": "recipe",
    "recipeBookCarousel": "Recipe book carousel",
    "recipeCarousel": "Recipe carousel",
    "tip": "Article"
  },
  "contentTypeHal": {
    "pushMessage": "Push notification"
  },
  "cookingVariable": {
    "errors": {
      "notInIterval": "Temperature must be in increments of {{ interval }} {{ unit }}"
    },
    "warnings": {
      "notInIterval": "{{ field }} should be in increments of {{ interval }} {{ unit }}"
    }
  },
  "copyToClipboard": {
    "label": {
      "shortID": "Copy short ID"
    }
  },
  "countryName": {
    "AA": "Global",
    "AE": "United Arab Emirates",
    "AQ": "Global",
    "AT": "Austria",
    "AU": "Australia",
    "BE": "Belgium",
    "BH": "Bahrain",
    "BR": "Brazil",
    "CA": "Canada",
    "CH": "Switzerland",
    "CN": "China",
    "DE": "Germany",
    "DK": "Denmark",
    "FI": "Finland",
    "FR": "France",
    "IN": "India",
    "LU": "Luxembourg",
    "NL": "Netherlands",
    "NO": "Norway",
    "NZ": "New Zealand",
    "PL": "Poland",
    "QA": "Qatar",
    "RU": "Russia",
    "SE": "Sweden",
    "SG": "Singapore"
  },
  "countryProfileModal": {
    "languageDoesNotMatchProfile": "Languages does not match to any country profile."
  },
  "countryProfilesModal": {
    "title": {
      "onlyPublish": "Select the countries you want to publish:",
      "unpublish": "Select the countries you want to publish to or deselect if you want to unpublish:"
    }
  },
  "cropping": {
    "crop": "Crop",
    "cropping": "Cropping image",
    "preparing": "Preparing cropper",
    "warning": {
      "keepCroppingButton": "Keep cropping",
      "leaveButton": "Leave anyway",
      "leaveContent": "You are currently cropping an image. Leaving will not save the image that is being cropped.",
      "saveButton": "Save anyway",
      "saveContent": "You are currently cropping an image. Image that is currently being cropped will not be saved.",
      "title": "Image is being cropped"
    }
  },
  "customTable": {
    "action": {
      "deleteTableAndData": "Delete the table and table data",
      "importData": "Import table data",
      "overwriteData": "Overwrite table data"
    },
    "column": {
      "addNew": "Add new column",
      "description": {
        "placeholder": "Enter column description"
      },
      "name": {
        "label": "Column name",
        "placeholder": "Enter column name"
      },
      "nullable": {
        "label": "Is nullable?"
      },
      "type": {
        "label": "Column type",
        "placeholder": "Select column type"
      }
    },
    "displayName": {
      "placeholder": "Enter display name"
    },
    "form": {
      "create": "Create custom table",
      "edit": "Edit custom table"
    },
    "header": {
      "columnName": "Column name",
      "columnType": "Column type",
      "isNullable": "Nullable"
    },
    "import": {
      "buttonLabel": "Import data",
      "description": "This action will overwrite the previous table data. The previous table data will be lost. Do you want to proceed with the action?",
      "modalTitle": "Import table data",
      "success": "Table data successfully imported"
    },
    "subtitle": {
      "columns": "Table columns",
      "dangerZone": "Danger zone"
    },
    "tableData": {
      "export": "Export table data",
      "noColumns": "Table columns are not defined",
      "noData": "There is no data in the table"
    },
    "tableDeletion": {
      "description": "This action is irreversible. The table and all of its data will be deleted. Do you want to proceed with the deletion?",
      "title": "Table deletion"
    },
    "toastMessage": {
      "saved": "Table definition saved"
    },
    "uniqueIdentifier": {
      "label": "Unique identifier",
      "placeholder": "Enter unique table identifier"
    }
  },
  "customTables": {
    "actions": {
      "editDefinition": "Edit definition"
    },
    "header": {
      "displayName": "Table name",
      "uniqueIdentifier": "Unique identifier"
    }
  },
  "dashboard": {
    "statistics": {
      "alternativeTitle": "Welcome!",
      "header": {
        "active": "Active",
        "active30": {
          "subtitle": "30 days",
          "title": "Active",
          "tooltip": "Used the app in the last month"
        },
        "all": "All",
        "live": "Live",
        "new30": {
          "subtitle": "30 days",
          "title": "New",
          "tooltip": "Users registered in the last month"
        },
        "other": "Other"
      },
      "subtitle": "Here’s what’s been happening in the HomeID community",
      "title": "Welcome, {{ name }}!",
      "total": "TOTAL"
    }
  },
  "deviceFamily": {
    "airfryer": "Airfryer",
    "blendersAndJuicers": "Blenders/Juicers"
  },
  "dialog": {
    "selectVisibleReason": "Please select the reason (the reason will be visible to consumer):",
    "title": {
      "requestDeletion": "Request deletion",
      "requestEdit": "Request edit"
    }
  },
  "domain": {
    "singular": "Domain"
  },
  "domainName": {
    "australia": "Australia",
    "benelux": "BENELUX",
    "cee": "CEE",
    "ceer": "CEER",
    "china": "China",
    "dach": "DACH",
    "france": "France",
    "global": "Global",
    "latam": "Latin America",
    "met": "MET",
    "middleeast": "Middle East",
    "nordics": "Nordics",
    "poland": "Poland",
    "singapore": "Singapore",
    "unitedarabemirates": "United Arab Emirates"
  },
  "error": {
    "generic": "Error",
    "httpResponse": "Server responded:",
    "http_0": "Something went wrong",
    "http_400": "Bad request",
    "http_401": "Unauthorized",
    "http_403": "Forbidden",
    "http_404": "Item not found",
    "http_500": "Internal server error",
    "isRequired": "{{ item }} is required",
    "itemExists": "Item already exists"
  },
  "exportTranslationsDialog": {
    "referenceLanguage": "Reference Language",
    "selectReferenceLanguage": "Select the reference language for export"
  },
  "fileUploader": {
    "uploadFailed": "Upload failed",
    "uploadSuccessful": "Upload successful",
    "uploading": "Uploading..."
  },
  "filter": {
    "announcementType": "Announcement type",
    "category": "Category",
    "devices": "Product model",
    "domain": "Domain",
    "language": "Language",
    "lockedIngredient": {
      "label": "Locked status",
      "locked": "Locked",
      "unlocked": "Unlocked"
    },
    "notInDatabases": "Not in databases",
    "nutritionDatabase": "Nutrition database",
    "ownerType": "Owner type",
    "qualityIndicator": {
      "calorieDifference": "kcal mismatch among databases",
      "fat": "High in fat",
      "label": "Quality indicator",
      "protein": "High in protein",
      "sodium": "High in sodium"
    },
    "status": "Status",
    "tags": {
      "label": "Tags",
      "notFound": "No tags found",
      "selectDevice": "Select appliance first"
    },
    "translatable": {
      "label": "Translatable status",
      "notTranslatable": "Not translatable",
      "translatable": "Translatable"
    },
    "useInRecipes": {
      "label": "Use in recipes",
      "onlyUnused": "Only unused ingredients",
      "onlyUsed": "Only used ingredients"
    }
  },
  "filterCreator": {
    "appliance": {
      "label": "Appliance",
      "placeholder": "Choose appliance"
    },
    "domain": {
      "label": "Domain",
      "placeholder": "Choose domain"
    },
    "language": {
      "label": "Language",
      "placeholder": "Choose language"
    },
    "languageStatus": {
      "label": "Language status",
      "placeholder": "Choose language status"
    },
    "ownerType": {
      "label": "Owner type",
      "placeholder": "Choose owner type"
    },
    "resourceType": {
      "label": "Content type",
      "placeholder": "Choose content type"
    },
    "status": {
      "label": "Status",
      "placeholder": "Choose status"
    }
  },
  "gram": {
    "abbreviation": "g"
  },
  "history": {
    "event": {
      "commentDeflag": "Comment deflagged",
      "commentDelete": "Comment deleted",
      "contentStatusChange": "Status changed",
      "ingredientsAnalyzed": "Ingredients analysed",
      "label": "Event",
      "missingUnitDetected": "Missing unit detected",
      "profileStatusChange": "Status changed",
      "publish": "Published",
      "unpublish": "Unpublished"
    },
    "newStatus": "New status",
    "noHistory": "No history"
  },
  "imageUpload": {
    "validationMessage": "You can upload only PNG or JPG images larger than {{ minWidth }}×{{ minHeight }}px and with ratio {{ ratioX }}×{{ ratioY }}"
  },
  "importResultType": {
    "invalidFileProvided": "Invalid file provided",
    "invalidTranslationProvided": "Invalid translation provided",
    "sourceMissing": "Source missing",
    "sourceTranslationChanged": "Source translation changed",
    "sourceTranslationMissing": "Source translation missing",
    "targetTranslationMissing": "Target translation missing",
    "translationImported": "Translation imported",
    "translationStillMissing": "Translation still missing"
  },
  "ingredient": {
    "addNew": {
      "default": "Add new ingredient"
    },
    "addToRecipe": {
      "amountMetric": "Amount (metric)",
      "name": "Ingredient name",
      "new": {
        "add": "Add \"{{ newIngredientName }}\" as ingredient"
      },
      "selectLanguageFirst": "Please select language before adding ingredients"
    },
    "allAppliances": "All appliances",
    "allergens": {
      "approveModalDescription": "You are about to approve food sensitivites and intolerances of this ingredient. Please enter your approval reason:",
      "approveModalTitle": "Approve food sensitivites and intolerances",
      "approved": "Food sensitivities and intolerances are approved.",
      "history": {
        "approved": "Approved Food sensitivities and intolerances.",
        "unapproved": "Unapproved Food sensitivities and intolerances."
      },
      "notApproved": "Food sensitivities and intolerances are not approved.",
      "unapproveModalDescription": "You are about to unapprove food sensitivites and intolerances of this ingredient. Please enter your unapproval reason:",
      "unapproveModalTitle": "Unapprove food sensitivites and intolerances"
    },
    "applianceType": "Appliance type",
    "author": {
      "label": "Ingredient author"
    },
    "choose": "Choose ingredient",
    "comment": {
      "noComment": "There are no comments on this ingredient",
      "placeholder": "Use this text area to enter comments about changes to the ingredient",
      "title": "Comments"
    },
    "create": "Create ingredient",
    "creator": {
      "admin": "Philips",
      "consumer": "Consumer",
      "table": {
        "admin": "P",
        "consumer": "C",
        "unknown": "-"
      },
      "unknown": "Not specified"
    },
    "details": {
      "title": "Ingredient details"
    },
    "displayName": "Display name",
    "history": "Ingredient history",
    "id": {
      "label": "Ingredient ID"
    },
    "ingredientTranslations": {
      "deleteModalTitle": "Deleting translation",
      "title": "Ingredient translations",
      "validator": {
        "plural": "Ingredient must have a translation for plural in language {{ language }}.",
        "singular": "Ingredient must have a translation for singular in language {{ language }}."
      }
    },
    "isInNutritionDatabase": "Is in nutrition database",
    "isNotInNutritionDatabase": "Is not in nutrition database",
    "isNotMappedToDatabase": "Is not mapped to database {{ database }}",
    "lock": "Lock ingredient",
    "locked": "Locked status",
    "lockedIngredient": {
      "history": {
        "approved": "Locked ingredient.",
        "unapproved": "Unlocked ingredient."
      },
      "tooltip": "If ingredient is locked it can not be deleted."
    },
    "merge": {
      "authorConflict": {
        "title": "Choose the author of the ingredient"
      },
      "conflictTitle": "Resolve conflict cases",
      "nutritionInfoConflict": {
        "title": "Choose which database mappings you want to keep"
      },
      "specificConversionConflict": {
        "title": "Choose which conversions you want to keep"
      },
      "translationConflict": {
        "title": "Choose which translations you want to keep"
      }
    },
    "mergeErrorModalTitle": "Error while replacing ingredient",
    "mergeModelTitle": "Replace “{{ ingredient }}” ingredient with",
    "mergeSuccessModalButton": "View ingredient",
    "mergeSuccessModalButtonToBrowse": "Browse ingredients",
    "mergeSuccessModalContent": "The ingredient was replaced with the {{ title }} ({{ id }}).",
    "mergeSuccessModalTitle": "Ingredient replaced",
    "missingMappings": {
      "long": "Please add nutritional database mappings for databases: {{ databases }}.",
      "modalTitle": "Ingredient is missing some database mappings",
      "short": "Missing mappings for databases: {{ databases }}"
    },
    "nameOrId": "Ingredient name or ID",
    "noResults": "No ingredients found",
    "noTranslations": {
      "description": "There is no translations for this ingredient",
      "title": "No translations"
    },
    "notTranslatable": {
      "history": {
        "approved": "Changed translatable status to not translatable.",
        "unapproved": "Changed translatable status to translatable."
      },
      "label": "Not translatable",
      "mergeTitle": "Choose which value you want to keep",
      "message": "Ingredient is not translatable.",
      "tooltip": "If ingredient is marked as not translatable its translation won’t be exported by default."
    },
    "nutritionMapping": {
      "addMapping": "Add nutrition mapping",
      "approveModalDescription": "You are about to approve nutrition information of this ingredient. Please enter your approval reason:",
      "approveModalTitle": "Approve nutrition information",
      "approved": "Nutrition information is approved.",
      "deleteModalTitle": "Deleting nutrition information mapping",
      "history": {
        "approved": "Approved Nutritional information.",
        "unapproved": "Unpproved Nutritional information."
      },
      "noMappings": "Nutritional information not available",
      "noResultInDatabase": "No nutrition info found in selected database",
      "notApproved": "Nutrition information is not approved.",
      "providerName": "Ingredient name",
      "providerNamePlaceholder": "Enter provider ingredient name",
      "sourceDatabase": "Source database",
      "sourceDatabasePlaceholder": "Select database",
      "tableHeader": {
        "ifc": "IFC",
        "providerText": "Provider text",
        "quantity": "Ref. quantity",
        "source": "Source",
        "unit": "Ref. unit"
      },
      "title": "Nutritional information",
      "unapproveModalDescription": "You are about to unapprove nutrition information of this ingredient. Please enter your unapproval reason:",
      "unapproveModalTitle": "Unapprove nutrition information"
    },
    "nutritionalBreakdown": {
      "source": "Source",
      "title": "Nutritional breakdown"
    },
    "ownershipModal": {
      "description": "Choose an appropriate author to which you want to reassign the ingredient. After you change the author to Philips you cannot change it back.",
      "title": "Change ingredient ownership"
    },
    "plural": "Ingredients",
    "reassignOwnership": {
      "button": "Change author"
    },
    "referencedInRecipes": {
      "label": "Referenced in recipes"
    },
    "replace": "Replace",
    "replaceWith": "Replace with ...",
    "requiresAtLeastOneConversion": {
      "long": "This ingredient does not have required conversion for selected unit.<br><br>Please convert at least one of these units to grams: {{ units }}.",
      "modalTitle": "Ingredient is missing some conversions",
      "short": "Missing conversion from {{ units }} to grams"
    },
    "saveChangesDialog": {
      "description": "Before continuing you need to save changes made to the ingredient.",
      "title": "Save changes?"
    },
    "singular": "Ingredient",
    "toastMessage": {
      "ownershipChanged": "Ingredient author changed",
      "saved": "Ingredient saved"
    },
    "translatable": {
      "label": "Translatable",
      "status": "Translatable status"
    },
    "translation": {
      "addNew": "Add new ingredient translation",
      "display": "Display",
      "edit": "Edit ingredient translation",
      "plural": "Plural",
      "singular": "Singular"
    },
    "translationConflict": {
      "message": "already has translation <span class=\"translation-highlight\">{{ name }}</span> in language <span class=\"translation-highlight\">{{ language }}</span>.",
      "title": "Existing translations"
    },
    "unitConversion": {
      "add": "Add unit conversion",
      "approveModalDescription": "You are about to approve specific unit conversion of this ingredient. Please enter your approval reason:",
      "approveModalTitle": "Approve specific unit conversion",
      "approved": "Specific unit conversions are approved.",
      "conversionAmount": "Conversion amount",
      "deleteModalTitle": "Deleting unit conversion",
      "destinationAmount": "Amount ({{ unit }})",
      "destinationAmountPlaceholder": "Enter conversion amount",
      "edit": "Edit unit conversion",
      "history": {
        "approved": "Approved Specific unit conversions.",
        "unapproved": "Unapproved Specific unit conversions."
      },
      "noConversions": "There are no unit conversions for this ingredient",
      "notApproved": "Specific unit conversions are not approved.",
      "sourceAmount": "Amount ({{ unit }})",
      "sourceAmountPlaceholder": "Enter source unit amount",
      "sourceUnit": "Source unit",
      "sourceUnitPlaceholder": "Select unit",
      "title": "Specific units conversion",
      "unapproveModalDescription": "You are about to unapprove specific unit conversion of this ingredient. Please enter your unapproval reason:",
      "unapproveModalTitle": "Unapprove specific unit conversion"
    },
    "view": "View ingredient"
  },
  "ingredientUnit": {
    "bag": {
      "plural": "bags",
      "singular": "bag"
    },
    "branch": {
      "plural": "branches",
      "singular": "branch"
    },
    "bunch": {
      "plural": "bunches",
      "singular": "bunch"
    },
    "can": {
      "plural": "cans",
      "singular": "can"
    },
    "centiliter": {
      "plural": "centiliters",
      "singular": "centiliter"
    },
    "clove": {
      "plural": "cloves",
      "singular": "clove"
    },
    "cup": {
      "plural": "cups",
      "singular": "cup"
    },
    "dash": {
      "plural": "dashes",
      "singular": "dash"
    },
    "decigram": {
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "plural": "deciliters",
      "singular": "deciliter"
    },
    "drop": {
      "plural": "drops",
      "singular": "drop"
    },
    "gram": {
      "plural": "grams",
      "singular": "gram"
    },
    "kilogram": {
      "plural": "kilograms",
      "singular": "kilogram"
    },
    "liter": {
      "plural": "liters",
      "singular": "liter"
    },
    "milligram": {
      "plural": "milligrams",
      "singular": "milligram"
    },
    "milliliter": {
      "plural": "milliliters",
      "singular": "milliliter"
    },
    "ounce": {
      "plural": "ounces",
      "singular": "ounce"
    },
    "package": {
      "plural": "packages",
      "singular": "package"
    },
    "piece": {
      "plural": "pieces",
      "singular": "piece"
    },
    "pinch": {
      "plural": "pinches",
      "singular": "pinch"
    },
    "sachet": {
      "plural": "sachet",
      "singular": "sachet"
    },
    "slice": {
      "plural": "slices",
      "singular": "slice"
    },
    "tablespoon": {
      "plural": "tablespoons",
      "singular": "tablespoon"
    },
    "teaspoon": {
      "plural": "teaspoons",
      "singular": "teaspoon"
    }
  },
  "ingredients": {
    "recipes": {
      "emtpyState": "There are no recipes for this ingredient"
    }
  },
  "ingredinet": {
    "action": {
      "plural": "Actions"
    }
  },
  "invalidEmail": {
    "errorMessage": "Invalid e-mail address"
  },
  "invalidIdsDialog": {
    "description": "As following ID inputs don’t match or don’t exist in the system they can not be shown in the preview list. Review the entered ID list and remove wrong IDs in order to succesfully save the list.",
    "title": "Wrong ID input",
    "wrongIdsSubtitle": "Wrong ID inputs"
  },
  "labels": {
    "searchInputPlaceholder": "Search recipe books"
  },
  "landingPage": {
    "goToChangelogButton": "See what's new",
    "title": "Welcome to AdminUI"
  },
  "language": {
    "choose": "Choose language",
    "singular": "Language"
  },
  "login": {
    "password": "Password",
    "username": "Username"
  },
  "macronutrients": {
    "title": "Macronutrients"
  },
  "menu": {
    "create": {
      "announcement": "Create announcement",
      "collection": "Create a recipe book",
      "ingredient": "Create ingredient",
      "nutriUStory": "Create article",
      "pinnedCollection": "Create a pinned recipe book",
      "pinnedRecipeBook": "Create a pinned recipe book",
      "plan": "Create a message plan",
      "recipeBookCarousel": "Create a recipe book carousel",
      "recipeBookPack": "Create a recipe book pack",
      "recipeCarousel": "Create a recipe carousel",
      "user": "Create user"
    },
    "customTables": {
      "create": "Create a custom table"
    },
    "header": {
      "adminUiUsers": "Admin UI users",
      "announcements": "Announcements",
      "bulkManagement": "Translations & bulk actions",
      "collections": "Recipe books",
      "comments": "Comments",
      "consumers": "Consumer list",
      "countries": "Countries",
      "customTables": "Custom tables",
      "dashboard": "Dashboard",
      "homeScreenCarousels": "Home Screen carousels",
      "ingredients": "Ingredients",
      "nutriUStories": "Articles",
      "packs": "Packs",
      "plannedMessages": "Personalized messaging system",
      "recipes": "Recipes",
      "reportedContent": "Reported content",
      "userAdministration": "User administration"
    },
    "import": {
      "translations": "Import translations"
    },
    "subtitle": {
      "administration": "Administration",
      "appHomeScreen": "APP HOME SCREEN",
      "communityManagement": "COMMUNITY MANAGEMENT",
      "contentManagement": "Content management",
      "publishing": "PUBLISHING"
    }
  },
  "milliliter": {
    "abbreviation": "ml"
  },
  "mimeType": {
    "xml": "XML",
    "zip": "ZIP"
  },
  "mineral": {
    "calcium": "Calcium",
    "iron": "Iron",
    "magnesium": "Magnesium",
    "potassium": "Potassium",
    "salt": "Salt",
    "selenium": "Selenium",
    "sodium": "Sodium",
    "title": "Minerals",
    "zinc": "Zinc"
  },
  "minute": {
    "abbrv": {
      "plural": "mins"
    }
  },
  "missingInformationDialog": {
    "defaultErrorMessage": "Some of the fields are not valid, please edit them.",
    "editFollowingInformation": "Please edit the following information:",
    "moveToDraftMessage": "Please move the {{ contentType}} to Draft status and edit missing information.",
    "title": "Please fill missing information"
  },
  "modal": {
    "areYouSure": "Are you sure?",
    "collectionDeviceTypeChange": {
      "changeRecipeDeviceType": "Change appliance type",
      "description": "The form will be reset. Are you sure?",
      "title": "Changing recipe book appliance type"
    },
    "pleaseSelectReason": "Please select the reason:",
    "recipeDeviceTypeChange": {
      "changeRecipeDeviceType": "Change appliance type",
      "description": "The form will be reset. Do you want to change appliance type?",
      "title": "Changing appliance type"
    }
  },
  "noCountryProfile": "No country profile",
  "notFound": {
    "description": "Page you entered doesn't exist.",
    "title": "Not found"
  },
  "nutriUStory": {
    "category": {
      "errorMessage": "Article category is required",
      "placeholder": "Choose article category"
    },
    "closeDialog": {
      "description": "You are about to leave this screen and discard the article information you entered. Do you want to save the article to continue editing later?",
      "title": "Save the article?"
    },
    "create": "Create article",
    "description": {
      "errorMessage": "Article description is required",
      "placeholder": "Enter article description"
    },
    "details": "Article details",
    "edit": "Edit article",
    "errors": {
      "coverImage": {
        "required": "Article must have cover image."
      },
      "generic": {
        "description": "Error is displayed in the console. Please contact developers.",
        "title": "Error while saving the article"
      },
      "link": {
        "notValid": "URL is not valid.",
        "required": "Link is required.",
        "requiredInStory": "Article URL is required."
      }
    },
    "id": "Article ID",
    "link": {
      "placeholder": "Paste link here"
    },
    "placeholder": {
      "noRecipes": "Article does not have any recipes"
    },
    "subType": {
      "accessory": "Accessory",
      "cooking": "Cooking",
      "interview": "Interview",
      "link": "Link",
      "nutritionClaimArticle": "Nutrition claim article",
      "product": "Product",
      "store": "Store"
    },
    "title": {
      "errorMessage": "Article title is required",
      "placeholder": "Enter article title"
    }
  },
  "nutriUStroy": {
    "subType": {
      "healthArticle": "Health story"
    }
  },
  "nutrient": {
    "alcohol": "Alcohol",
    "calories": "Calories",
    "carbohydrates": "Carbohydrates",
    "cholesterol": "Cholesterol",
    "dietaryFibers": "Dietary fibers",
    "fat": "Fat",
    "omega3FattyAcids": "Omega-3 fatty acids",
    "organicAcids": "Organic acids",
    "protein": "Protein",
    "saturatedFat": "Saturated fat",
    "sugar": "Sugar",
    "unsaturatedFattyAcids": "Unsaturated fatty acids",
    "water": "Water"
  },
  "nutrientTable": {
    "nrv": "% NRV*",
    "per100g": "Per 100 g",
    "per100ml": "Per 100 ml"
  },
  "nutrientUnits": {
    "gram": "g",
    "kcal": "kcal",
    "microgram": "μg",
    "miligram": "mg"
  },
  "nutritionClaim": {
    "plural": "Nutrition claims",
    "singular": "Nutrition claim"
  },
  "nutritionalClaimGuidline": {
    "defaultDescription": "Unknown nutritional claim type",
    "description": "{{ claimReference }} must contain {{ comparatorValue }} than {{ value }} of {{ nutrient }}",
    "less": "less",
    "more": "more",
    "percentageOdTotalCalories": "{{ nutrient }} must take up {{ comparatorValue }} than {{ value }} of the total amount of calories. 1 {{ unit }} of {{ nutrient }} is {{ nutrientKcalValue }} kcal.",
    "recommendedDailyIntake": "the recommended daily intake",
    "totalKcal": "total kcal"
  },
  "nutriuStory": {
    "applianceType": {
      "errorMessage": "Appliance type is required",
      "label": "Appliance type",
      "placeholder": "Choose appliance type"
    },
    "recipeNotValid": {
      "translationNotApproved": {
        "title": "Recipe is not valid for the article"
      },
      "wrongAuthorType": {
        "description": "Recipe author must be Philips"
      }
    }
  },
  "pagination": {
    "next": "Next",
    "previous": "Previous"
  },
  "pinnedCollection": {
    "activation": {
      "contentWillBeDeactivated": "<div>You are activating pinned recipe book for {{ country }}, therefore, {{ collectionBundlesForDeactivation }} will be deactivated.</div>"
    },
    "addBasicInformation": "Add basic information",
    "details": "Pinned recipe book details",
    "enterTitle": "Pinned recipe book title",
    "pinCollection": "Pin a recipe book",
    "saveThePinnedCollection": "Save the pinned recipe book?",
    "title": "Pinned recipe book title",
    "toastMessage": {
      "saved": "Pinned recipe book saved"
    },
    "validation": {
      "notSuitableForCountry": "Pinned recipe book is not published to the country"
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the pinned recipe book information you entered. Do you want to save the pinned recipe book to continue editing later?"
  },
  "placeholder": {
    "add": {
      "description": "Add description",
      "title": "Add title"
    },
    "enter": {
      "amount": "Enter amount",
      "collectionName": "Enter recipe book name",
      "ingredientName": "Enter ingredient name",
      "ingredientNamePlural": "Enter ingredient name plural",
      "ingredientNameSingular": "Enter ingredient name singular",
      "measurement": "Enter measurement",
      "minutes": "Enter minutes",
      "seconds": "Enter seconds"
    }
  },
  "plannedMessages": {
    "addRulesetModal": {
      "formType": {
        "label": "Form type",
        "title": "Choose a type of form you want to create"
      },
      "reducer": {
        "label": "Operator",
        "title": "Choose how you want to connect the rules"
      },
      "required": "All fields must be selected.",
      "rule": {
        "label": "Rule selection",
        "placeholder": "Choose a rule"
      },
      "rulesetName": {
        "label": "Ruleset name",
        "placeholder": "Enter ruleset name"
      }
    },
    "createPlan": "Create a message plan",
    "editPlan": "Edit the message plan",
    "enterPlanTitle": "Enter plan title",
    "message": {
      "addNew": "Add new message",
      "label": "Message",
      "linkToArticleLabel": "Link to article",
      "locationType": "Location type",
      "messageWillBeLostWarning": "The delivery type is not selected. This message will be lost once the browser tab is closed. ",
      "placeholder": "Enter message here"
    },
    "messageGroup": {
      "addNew": "Add new message group",
      "label": "Message group"
    },
    "noRulesetApplied": {
      "add": "Add a rule/ruleset",
      "description": "There is no ruleset applied to this group",
      "title": "No ruleset applied"
    },
    "note": {
      "label": "Note",
      "placeholder": "Enter note"
    },
    "plan": {
      "activateModal": {
        "content": "Are you sure you want to change this plan's status?",
        "title": "Change plan status"
      },
      "button": {
        "activatePlan": "Activate plan",
        "deactivatePlan": "Deactivate plan"
      },
      "deleteModal": {
        "content": "Are you sure you want to delete this plan?",
        "title": "Delete plan"
      },
      "messageSection": {
        "title": "Message plan details"
      },
      "noPlans": "There are no plans",
      "note": {
        "label": "Note"
      },
      "planId": {
        "label": "Message plan ID"
      },
      "status": {
        "active": "Active",
        "inactive": "Inactive",
        "label": "Status"
      },
      "view": {
        "rulesetsTitle": "Rulesets & message groups"
      }
    },
    "planAction": {
      "addNew": "Add new message group rule",
      "category": {
        "label": "Category",
        "notFound": "Action category not found",
        "placeholder": "Choose a category",
        "required": "Action category is required"
      },
      "deleteModal": {
        "content": "Deleting this message group rule will remove all the nested messages group rules and their messages. Are you sure you want to delete it?",
        "title": "Delete message group rule"
      },
      "label": "Message group rule",
      "name": {
        "placeholder": "Enter name",
        "required": "Action name is required."
      }
    },
    "planStepMessage": {
      "activateModal": {
        "content": "Are you sure you want to change this message's status?"
      },
      "deleteModal": {
        "content": "Are you sure you want to delete this message?",
        "title": "Delete message"
      },
      "statusModal": {
        "title": "Change message status"
      }
    },
    "rule": {
      "label": "Rule"
    },
    "ruleset": {
      "addNewRuleToRuleset": "Add a rule/ruleset",
      "collapse": "Collapse ruleset",
      "expand": "Expand ruleset",
      "label": "Ruleset",
      "removeRule": {
        "description": "Are you sure you want to remove the rule?",
        "title": "Rule removing"
      },
      "removeRuleset": {
        "description": "Are you sure you want to remove the ruleset?",
        "title": "Ruleset removing"
      }
    },
    "tableHeaderTitle": "Name",
    "validation": {
      "title": {
        "required": "Plan title is required"
      }
    }
  },
  "preparedMeal": {
    "action": {
      "deflag": "Deflag image",
      "delete": "Delete image"
    },
    "changeStatus": {
      "deflag": {
        "content": "You are about to deflag this recipe image. Please enter your reason:",
        "title": "Deflagging image"
      }
    },
    "deflaggedToastMessage": "Image successfully deflagged.",
    "delete": {
      "content": "You are about to delete this recipe image. Please enter your reason:",
      "title": "Deleting image"
    },
    "inRecipe": "In recipe",
    "title": "Image from {{ owner }}"
  },
  "processingStep": {
    "addNewStep": "Add new step",
    "durationWarning": "Processing time must be between {{ min }} and {{ max }}",
    "editStep": "Edit step",
    "enterStepDescription": "Enter step description",
    "errorMessage": {
      "description": "All steps must have a description",
      "title": "Step must have a title"
    },
    "header": {
      "description": "Description",
      "photo": "Photo",
      "stepType": "Step type",
      "time": "Time",
      "title": "Step title"
    },
    "processingTime": "Processing time",
    "step": "Step {{stepNumber}}",
    "stepDescription": "Step description",
    "stepTypePlaceholder": "Choose step type",
    "temperatureWarning": "Temperature must be between {{ min }}°C and {{ max }}°C",
    "uploadStepImage": "Upload step image"
  },
  "pushMessage": {
    "errors": {
      "delete": {
        "title": "Error while deleting the push notification"
      }
    },
    "toastMessage": {
      "delete": "The push notification has been deleted"
    },
    "view": "View push notification"
  },
  "qualityIndicator": {
    "calorieDifference": "There is a significant difference in kcal values between the databases. Please check.",
    "fat": "fat",
    "group": {
      "fatProteinSodium": "This recipe is high in {{ indicatorsList }}. Please check."
    },
    "hover": {
      "fat": "The amount of fat is higher than {{ limit }} per serving",
      "protein": "The amount of protein is higher than {{ limit }} per serving",
      "sodium": "The amount of sodium is higher than {{ limit }} per serving"
    },
    "protein": "protein",
    "sodium": "sodium"
  },
  "recipe": {
    "activePreparation": {
      "errorMessage": "Active preparation time is required",
      "label": "Active preparation",
      "placeholder": "Enter active preparation time",
      "tooltip": "Time spent washing, slicing, and dicing ingredients"
    },
    "activeTime": {
      "label": "Active time",
      "tooltip": "Processing time + Active preparation"
    },
    "addToCollection": {
      "content": "A recipe can only be added to the DRAFT and APPROVED recipe books",
      "recipeAlreadyInCollection": "Recipe is already in recipe book \"{{ collectionName }}\"",
      "saveButtonLabel": "Add recipe",
      "successMessage": "Recipe added to recipe book \"{{ collectionName }}\"",
      "title": "Add recipe “{{ title }}” to a recipe book"
    },
    "blenderTotalTime": {
      "tooltip": "Active + Passive preparation"
    },
    "chageDomainToGlobal": {
      "dialog": {
        "saveButton": "Yes, move to GLOBAL",
        "title": "Are you sure you want to change your local domain to GLOBAL?"
      },
      "toastMessage": "Recipe domain successfully changed to GLOBAL"
    },
    "changeStatus": {
      "delete": {
        "content": "Please select the reason (the reason will be visible to consumer):",
        "title": "Recipe deletion"
      }
    },
    "create": "Create {{device}} recipe",
    "details": "Recipe details",
    "downloadSuccessMessage": "The recipe is downloaded.",
    "edit": "Edit {{device}} recipe",
    "errorMessage": {
      "numberOfServings": {
        "max": "Number of servings must be between {{ min }} and {{ max }}",
        "min": "Number of servings must be greater than {{ min }}"
      }
    },
    "errors": {
      "imageRequired": "Image is required"
    },
    "export": "Recipe export",
    "id": "Recipe ID",
    "import": "Import recipe",
    "importDescription": "Click select file or drag and drop your ZIP file",
    "ingredient": {
      "delete": {
        "content": "Are you sure you want to delete the ingredient?",
        "title": "Delete ingredient"
      }
    },
    "ingredientPriority": {
      "main": "Main",
      "regular": "Regular"
    },
    "maxNumberOfServings": {
      "label": "Maximum servings"
    },
    "missingDialogOptions": {
      "question": "Do you want to edit the recipe now?"
    },
    "noIngredients": "Recipe doesn't have any ingredients.\n",
    "noProcessingSteps": "Recipe doesn't have any steps.",
    "noTags": "Recipe doesn't have any tags.",
    "numberOfServings": {
      "label": "Number of Servings",
      "placeholder": "Enter number of servings"
    },
    "nutriInfoCalculation": {
      "beingRecalculated": "Nutritional information is currently being recalculated.",
      "canNotBeCalculated": "Main ingredients can't be calculated. Please check if any data mappings or conversions are missing.",
      "inAFewMinutes": "Nutritional information will be available in a few minutes.",
      "pleaseCheck": "Please check if any data mappings or conversions are missing.",
      "willBeAvailableSoon": "Information will be available soon."
    },
    "nutriUStory": {
      "notUsed": "Recipe is not being used in any article."
    },
    "nutritionalInformation": {
      "breakdown": {
        "caloriesBreakdownGraphTitle": "Calories breakdown",
        "hide": "Hide breakdown",
        "show": "Show breakdown"
      },
      "countrySelectionLabel": "Display nutritional information for",
      "disclaimer": {
        "amountPerServing": "Amounts are per serving. <strong>One serving is 1/{{ numberOfServings }} of “{{ recipeTitle }}” recipe</strong>.",
        "dailyValues": "* Nutrient reference value is based on a {{ baseCalories }} kcal diet. Your daily values may be higher or lower depending on your calorie needs.",
        "numerOfServings": "Each serving is<br>1/{{ numberOfServings }} recipe"
      },
      "kcal": "kcal",
      "perServing": "Per serving",
      "sectionTitle": "Nutritional information",
      "tab": {
        "energy": "Energy",
        "nutrients": "Nutrients"
      }
    },
    "passivePreparation": {
      "label": "Passive preparation",
      "placeholder": "Enter passive preparation time",
      "tooltip": "Time spent drying, soaking or freezing ingredients"
    },
    "preparedMeals": {
      "emptyState": "There are no images for selected country",
      "sectionTitle": "Recipe images by consumers"
    },
    "reassignLanguage": {
      "description": "Choose a supported language of the recipe’s country.",
      "toastMessage": "Recipe language was changed to {{ language }}"
    },
    "similarIngredientsDialog": {
      "description": "Looks like the recipe you want to save has very similar ingredients as the following recipes:",
      "title": "Ingredient combination detected"
    },
    "step": {
      "delete": {
        "content": "Are you sure you want to delete the step?",
        "title": "Delete step"
      }
    },
    "tag": {
      "difficulty": {
        "easyToStart": "Yes",
        "label": "Easy to start",
        "notEasyToStart": "No",
        "placeholder": "Easy to start"
      }
    },
    "titleConflict": {
      "explanation": "Please make sure you are not creating a duplicate recipe, as there is already one existing using the same name",
      "inLanguage": "in language"
    },
    "toastMessage": {
      "saved": "Recipe has been successfully saved",
      "translationSaved": "Recipe translation saved successfully."
    },
    "totalTime": {
      "errorMessage": "Total time must be greater than 0",
      "label": "Total time",
      "tooltip": "Processing time + Active preparation + Passive preparation"
    },
    "translations": {
      "export": {
        "includeNonTranslatable": "Include not translatable ingredients",
        "noCommonLanguages": "Selected items don't have common languages."
      }
    },
    "view": "View recipe"
  },
  "recipeBookCarousel": {
    "addCollectionsToCarousel": "Add recipe books to the carousel (min.{{ minCollections }})",
    "addedCollectionsToCarousel": "Added recipe books to the carousel ({{ currentValue }})",
    "collections": {
      "emptyStateMessage": "There are no recipe books in this carousel."
    },
    "details": "Recipe book carousel details",
    "enterTitle": "Recipe book carousel title",
    "noRecipeBooksAddedToCarousel": "There are no recipe books added to this carousel.",
    "recipeBookRemovalDescription": "Are you sure you want to remove the recipe book from the carousel?",
    "recipeBookRemovalTitle": "Remove from carousel",
    "saveTheRecipeBookCarousel": "Do you want to save the changes before leaving?",
    "title": "Recipe book carousel title",
    "toastMessage": {
      "saved": "Recipe book carousel saved"
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe book carousel information you entered. Do you want to save the recipe book carousel to continue editing later?"
  },
  "recipeCarousel": {
    "details": "Recipe carousel details",
    "enterTitle": "Recipe carousel title",
    "pinCollection": "Add a recipe book to carousel",
    "recipeBookRemovalTitle": "Remove from carousel",
    "saveTheRecipeCarousel": "Save the recipe carousel?",
    "title": "Recipe carousel title",
    "toastMessage": {
      "saved": "Recipe carousel saved"
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe carousel information you entered. Do you want to save the recipe carousel to continue editing later?"
  },
  "recipeDetails": {
    "commentsByConsumers": "Comments by consumers"
  },
  "recipeForm": {
    "nutritionalInformation": {
      "label": "Note",
      "placeholder": "Enter note"
    },
    "subtitle": {
      "basicInformation": "Add basic information",
      "ingredient": "Add ingredient",
      "nutritionalInformation": "Add nutritional information",
      "servings": "Servings",
      "timeAndServings": "Time and servings"
    }
  },
  "recipeLock": {
    "title": "Another user is editing this recipe",
    "warning": "Recipe can't be edited. User {{ user }} is currently editing this recipe."
  },
  "recipeRepublish": {
    "missingApprovedTranslations": "Translation for {{label}} profile is not approved or does not exist.\n",
    "warningMessage": {
      "published": "This recipe is already published to this Country Profile. Do you want to republish it as a new recipe?",
      "unpublished": "This recipe was already published to this Country Profile. Do you want to publish it as a new recipe or republish it to its previous position?"
    }
  },
  "recipeTranslations": {
    "clickSelectFileOrDragXmlOrZip": "Click select file or drag and drop your {{ fileTypes }} files",
    "keywords": {
      "label": "Keywords",
      "placeholder": "Add keywords"
    }
  },
  "recipeValidator": {
    "ingredientsBelowLimit": "Recipe has more than {{limit}} ingredients",
    "processingStep": {
      "preheatingOrderErrorMessage": "Please move the processing step with Preheat cooking method at least one place before the earliest processing step.",
      "preheatingOrderErrorTitle": "Error in recipe steps"
    },
    "requiredIngredient": "Recipe must have at least one ingredient"
  },
  "recipes": {
    "exportTranslations": "Export translations"
  },
  "reportReason": {
    "inappropriateLanguage": "Inappropriate language",
    "inappropriateOrAbusiveContent": "Inappropriate or abusive content",
    "incompleteContent": "Incomplete content",
    "incorrectContent": "Incorrect content",
    "other": "Other",
    "spam": "Spam"
  },
  "reportedContent": {
    "dialog": {
      "subtitle": "Please enter additional reason (this won't be visible to the consumer)",
      "takingOfflineSubtitle": "Please enter additional reason"
    },
    "statusIndicatorLabel": "Unresolved reports"
  },
  "reportedContentStatus": {
    "inReview": "In review",
    "reported": "Reported",
    "reviewed": "Reviewed"
  },
  "reportingInformation": {
    "authorType": {
      "philips": "Philips"
    },
    "commentedBy": "Commented by",
    "noReports": "No reports",
    "products": "consumer source pro.",
    "reason": "Reason",
    "reportReason": "Report reason",
    "reportStatus": "Report status",
    "reportedAt": "Reported at",
    "reportedBy": "Reported by",
    "title": "Reporting information",
    "toastMessage": {
      "copyIDSuccess": "User ID copied to clipboard"
    }
  },
  "republishPosition": {
    "previous": "Previous position",
    "top": "New recipe"
  },
  "resourceType": {
    "collection": "Recipe book",
    "ingredient": "Ingredient",
    "recipe": "Recipe",
    "tip": "Article"
  },
  "richItemSelector": {
    "emptyStateLabel": "There are no items added.",
    "fetchDisabledLabel": "Please fill required information first",
    "itemRemovalDescription": "Are you sure you want to remove the item from the selected items?",
    "itemRemovalTitle": "Remove item",
    "noSearchResultsLabel": "No items found for",
    "searchInputPlaceholder": "Search items",
    "searchItemsTitle": "Add items",
    "selectedItemsSearchBarInputPlaceholder": "Search added items",
    "selectedItemsTitle": "Selected items ({{ selectedItemsCount }})"
  },
  "role": {
    "chef": "Chef",
    "communityManager": "Community manager",
    "contentManager": "Content manager",
    "globalContentManager": "Global content manager",
    "ingredientManager": "Ingredient manager",
    "nutritionist": "Nutritionist",
    "readOnlyAccess": "Read only access",
    "systemAdministrator": "System administrator"
  },
  "savedFilters": {
    "clearFilters": "Clear filters",
    "selecteFilterSet": "Select saved filter set",
    "youDontHaveAnySets": "You don't have any saved filter sets",
    "youDontHaveAnySetsForDomain": "You don't have any saved filter sets for this domain"
  },
  "searchBar": {
    "deleteCustomFilterTitle": "Do you want to delete filter set \"{{ filterName }}\"?"
  },
  "searchbar": {
    "clearFilters": "Clear",
    "saveSet": "Save set",
    "tab": {
      "all": "All",
      "allContent": "All content",
      "allDomains": "All domains",
      "collections": "Recipe books",
      "comments": "Comments",
      "consumers": "Consumers",
      "nutriUStories": "Articles",
      "preparedMeal": "Consumer images",
      "recipes": "Recipes"
    }
  },
  "selectLabel": {
    "self": "{{label}} (you)"
  },
  "sensitiveFieldChecks": {
    "article": {
      "steps": {
        "added": "Added new article step"
      }
    },
    "collection": {
      "translation": {
        "description": "Changed recipe book description",
        "title": "Changed recipe book title"
      }
    },
    "modal": {
      "details": "All Approved translations will be moved to Draft status. Translations need to be updated due to the following content changes:",
      "title": "Translations moved to Draft"
    },
    "recipe": {
      "ingredients": {
        "added": "Added new recipe ingredient",
        "changed": "Changed recipe ingredient(s)"
      },
      "steps": {
        "added": "Added new step",
        "changed": "Changed step(s) description"
      },
      "translation": {
        "description": "Changed recipe description",
        "keywords": "Changed recipe keywords",
        "title": "Changed recipe title"
      }
    },
    "tip": {
      "processingStepTranslation": {
        "changedDescription": "Changed processing step(s) description",
        "changedTitle": "Changed processing step(s) title"
      },
      "processingSteps": {
        "added": "Added new processing step"
      },
      "translation": {
        "description": "Changed article description",
        "title": "Changed article title"
      }
    }
  },
  "sidebar": {
    "releaseNotes": "See what's new"
  },
  "ssoLandingPage": {
    "buttonText": "I want to login with my <strong>{{sso}}</strong> email",
    "cardTitle": "One step away from AdminUI!",
    "chooseProviderText": "Please choose the email domain you want to login with to proceed to AdminUI."
  },
  "status": {
    "approved": "Approved",
    "blocked": "Blocked",
    "deleted": "Deleted",
    "deletedByCM": "Deleted by Content Manager",
    "deletedByConsumer": "Deleted by consumer",
    "draft": "Draft",
    "inReview": "In review",
    "live": "Live",
    "liveFlagged": "Live flagged",
    "quarantined": "Quarantined"
  },
  "storeCategory": {
    "allProducts": "All products",
    "singleProduct": "Single product"
  },
  "story": {
    "toastMessage": {
      "saved": "Article saved",
      "translationSaved": "Article translation saved successfully"
    }
  },
  "table": {
    "header": {
      "action": "Action",
      "actions": "Actions",
      "announcementInfo": "Announcement info",
      "appliance": "Appliance",
      "category": "Category",
      "collectionInfo": "Recipe book info",
      "commentBy": "Comment by",
      "commentText": "Comment text",
      "countriesPublished": "Countries published",
      "dateAdded": "Date added",
      "dateCreated": "Date created",
      "datePosted": "Date posted",
      "domain": "Domain",
      "info": "Info",
      "modified": "Last modified",
      "nutritionInfo": "Nutrition info",
      "published": "Published date",
      "recipeInfo": "Recipe info",
      "title": "Title",
      "translations": "Translations",
      "type": "Type",
      "userType": "Owner type"
    }
  },
  "timeRangeOptions": {
    "allTime": "All time",
    "lastDay": "Past 24 hours",
    "lastMonth": "Past month",
    "lastWeek": "Past week",
    "lastYear": "Past year"
  },
  "tip": {
    "accessoryType": "Accessory type",
    "errors": {
      "imageRequired": "Image is required"
    },
    "missingDialogOptions": {
      "question": "Do you want to edit the article now?"
    },
    "noProcessingSteps": "Article doesn't have any steps.",
    "titleConflict": {
      "explanation": "Please make sure you are not creating a duplicate article, as there is already one existing using the same name",
      "inLanguage": "in language"
    }
  },
  "toast": {
    "translationDownload": "Translation download started"
  },
  "togglableSearchList": {
    "emptyStateLabel": "No items found",
    "itemRemovalDescription": "Are you sure you want to remove the item from the list?",
    "itemRemovalTitle": "Remove from list",
    "searchInputPlaceholder": "Search items"
  },
  "translations": {
    "changeStatus": {
      "dialogContent": "Are you sure you want to change translation status?",
      "dialogTitle": "Changing translation status"
    },
    "delete": {
      "dialogTitle": "Deleting translation"
    },
    "import": {
      "error": {
        "content": "content",
        "title": "Error while importing {{ contentType }}"
      },
      "success": "Translations imported"
    },
    "noViewPermission": "You have no permission to view translations.",
    "notAvailable": "No translations available",
    "reassignSourceLanguage": {
      "label": "Reassign source language\n"
    },
    "status": {
      "approved": "Approved",
      "missing": "Missing",
      "unapproved": "Unapproved"
    },
    "statusChange": {
      "toastMessage": "Translation status changed successfully."
    }
  },
  "unit": {
    "minute": {
      "plural": "minutes"
    },
    "second": {
      "plural": "seconds"
    }
  },
  "unknownTranslationFor": {
    "ingredient": "Unknown translation for ingredient {{id}}"
  },
  "user": {
    "assignedDomains": "Assigned domains",
    "assignedUserRoles": "Assigned user roles",
    "browse": {
      "title": "Browse users"
    },
    "company": {
      "label": "Company",
      "placeholder": "Enter company"
    },
    "deactivatedAt": "Deactivated at",
    "deactivationInformation": "Deactivation information",
    "details": "User details",
    "domainRelatedUserRoles": "Domain related user roles",
    "email": {
      "label": "Contact e-mail",
      "placeholder": "Enter contact e-mail"
    },
    "form": {
      "close": {
        "content": "You are about to leave this screen and discard the user information you entered. Do you want to save the user to continue editing later?",
        "title": "Save user?"
      },
      "create": "Create user",
      "domainSelection": "Domain selection",
      "edit": "Edit user",
      "placeholder": {
        "domains": "Assigned countries",
        "role": "Choose user role"
      }
    },
    "id": "User ID",
    "modifiedAt": "Last modified",
    "name": {
      "isRequired": "Name is required",
      "label": "Name",
      "placeholder": "Enter name"
    },
    "permissions": "Permissions",
    "registeredAt": "Registered at",
    "role": "Role",
    "status": {
      "active": "Active",
      "deleted": "Deactivated"
    },
    "statusChangeDialog": {
      "deactivate": {
        "description": "User will be deactivated and won't be able to change or view content.",
        "title": "Are you sure you want to deactivate this user?"
      },
      "reactivate": {
        "title": "Reactivate this user?"
      }
    },
    "toastMessage": {
      "deactivated": "User deactivated",
      "reactivated": "User reactivated"
    },
    "user": "User",
    "userName": "User name",
    "userRole": "User role",
    "userRolesAndPermission": "Roles and permissions",
    "username": {
      "isRequired": "Username is required",
      "label": "Username",
      "placeholder": "Enter username"
    },
    "usernameExistsDialog": {
      "description": "An account with entered username already exists. View their user profile or change username.",
      "saveLabel": "View user",
      "title": "Username already exists"
    }
  },
  "users": {
    "searchPlaceholder": "Find user by name or email address"
  },
  "validation": {
    "processingStep": {
      "required": "Recipe must have at least one processing step.",
      "requiredByAirfyer": "Processing steps must have temperatures and processing times specified."
    }
  },
  "validator": {
    "mustBeInteger": "Input can't be a decimal number."
  },
  "viewType": {
    "social": "Social",
    "technical": "Technical"
  },
  "vitamin": {
    "biotin": "Biotin",
    "folicAcid": "Folic Acid",
    "niacin": "Niacin",
    "pantothenicAcid": "Pantothenic acid",
    "riboflavin": "Riboflavin",
    "thiamin": "Thiamin",
    "title": "Vitamins",
    "vitaminA": "Vitamin A",
    "vitaminB12": "Vitamin B-12",
    "vitaminB6": "Vitamin B-6",
    "vitaminC": "Vitamin C",
    "vitaminD": "Vitamin D",
    "vitaminE": "Vitamin E",
    "vitaminK": "Vitamin K"
  },
  "warning": {
    "iedge": {
      "description": "It seems you are using Internet Explorer or Edge browser. Application might not behave as expected.\n\n<br><br>\n\nIf you experience any issues, please try using Google Chrome or Mozilla Firefox.",
      "title": "Internet Explorer / Edge"
    }
  }
}